<template>
    <div class="row h-100 align-items-center">
        <div class="col text-center" v-for="item in news">
            <h3>
                {{ city.name }}
            </h3>
            <h1>
                {{ city.main.temp }} C
            </h1>
            <h4>
                {{ city.weather[0].main }}
            </h4>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                news: Object
            };
        },
        created: function () {
            this.getNewsFeed();
        }
        ,
        methods: {
            getNewsFeed: function () {
                this.$http.get("http://feeds.feedburner.com/TechCrunch/social").then(function (response) {
                    console.log(response);
                });
            }
        }
    }
</script>