<template>

    <div class="app container-fluid h-100">

        <div class="row">
            <section id="app" class="col">
                <transition name="fade" mode="out-in">
                    <router-view></router-view>
                </transition>
            </section>
        </div>

        <fc-footer></fc-footer>

    </div>

</template>

<script>
    import FcHeader from './Components/FcHeader.vue';
    import FcFooter from './Components/FcFooter.vue';

    export default {
        components: {
            FcHeader, FcFooter
        },
        data() {
            return {
                modules: [
                    {
                        route: '/slide',
                        delay: 1000000
                    },
                    {
                        route: '/motd',
                        delay: 60000
                    },
                    {
                        route: '/weather',
                        delay: 60000
                    }
                ],
                nextModuleId: 0
            }
        },
        methods: {
            cycleModule: function (moduleId) {
                this.$router.push(this.modules[moduleId].route);

                if (moduleId + 1 < this.modules.length)
                    this.nextModuleId = moduleId + 1;
                else
                    this.nextModuleId = 0;

                setTimeout(this.cycleModule, this.modules[moduleId].delay, this.nextModuleId);
            },
            nextModule: function() {
                this.cycleModule(this.nextModuleId);
            }
        },
        mounted() {
            this.cycleModule(this.nextModuleId);
        },
        created() {
            this.$events.$on('cycleCompleted', this.nextModule);
        },

        name: 'app'
    }
</script>