var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app container-fluid h-100" },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "section",
          { staticClass: "col", attrs: { id: "app" } },
          [
            _c(
              "transition",
              { attrs: { name: "fade", mode: "out-in" } },
              [_c("router-view")],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("fc-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }