<template>
    <footer class="row align-items-center">
        <div class="col-6 text-left">
            <span class="fc-icon fc-facelift-cloud "></span>
        </div>
        <div class="col-6 text-right">
            {{ now | moment("dddd") }} ⋅ {{ now | moment("D. MMMM YYYY") }} ⋅ {{ now | moment("HH:mm") }}
        </div>
    </footer>
</template>

<script>
    export default {

        mounted() {
            window.setInterval(() => {
                this.now = new Date();
            }, 1000);
        },

        data() {
            return {
                now: new Date()
            }
        }

    };
</script>                     