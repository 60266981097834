<template>
    <div class="row h-100 align-items-center">
        <div class="col text-center" v-for="city in cities">
            <h3>
                {{ city.name }}
            </h3>
            <h1>
                <i style="font-size: 7vh" :class="'wi wi-owm-' + city.weather[0].id + ''"></i>
            </h1>
            <h1>
                {{ city.main.temp | round }}°
            </h1>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                cities: Object
            };
        },
        created: function () {
            this.getWeatherData();
        },
        filters: {
            round: function (value) {
                return value.toFixed(0);
            }
        },
        methods: {
            getWeatherData: function () {
                const api_key = "245c44e1e7929dbffe976385a4595883";
                this.$http.get("https://api.openweathermap.org/data/2.5/group?id=2911298,2988507,292223,1172451&units=metric&appid=" + api_key).then(function (response) {
                    this.cities = response.body.list;
                });
            }
        }
    }
</script>