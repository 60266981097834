var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row h-100 align-items-center" },
    _vm._l(_vm.news, function(item) {
      return _c("div", { staticClass: "col text-center" }, [
        _c("h3", [
          _vm._v("\n            " + _vm._s(_vm.city.name) + "\n        ")
        ]),
        _vm._v(" "),
        _c("h1", [
          _vm._v("\n            " + _vm._s(_vm.city.main.temp) + " C\n        ")
        ]),
        _vm._v(" "),
        _c("h4", [
          _vm._v(
            "\n            " + _vm._s(_vm.city.weather[0].main) + "\n        "
          )
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }