import '../css/app.scss';
import Vue from 'vue';
import VueRouter from 'vue-router';
import VueResource from 'vue-resource';
import VueMoment from 'vue-moment';
import VueEvents from 'vue-events';

import Main from './Main.vue';
import FcStart from './Components/FcStart.vue';
import FcMotd from './Components/FcMotd.vue';
import FcWeather from './Components/FcWeather.vue';
import FcNewsFeed from './Components/FcNewsFeed.vue';
import FcSlide from './Components/FcSlide';

Vue.use(VueRouter);
Vue.use(VueResource);
Vue.use(VueMoment);
Vue.use(VueEvents);

const routes = [
    { name: 'start', path: '/', component: FcStart },
    { name: 'motd', path: '/motd', component: FcMotd },
    { name: 'weather', path: '/weather', component: FcWeather },
    { name: 'news', path: '/news', component: FcNewsFeed },
    { name: 'slide', path: '/slide', component: FcSlide },
];

const router = new VueRouter({
    routes
});

let app = new Vue({
    el: '#app',
    router,
    data: {
        message: 'Hello Vue!'
    },
    template: '<Main/>',
    components: { Main }
});

