<template>
    <div class="row h-100">
        <div class="col main slide" v-bind:style="{ backgroundImage: 'url(' + slide.image + ')' }">
        </div>
    </div>
</template>

<script>
    export default {
        name: "fc-slide",
        data() {
            return {
                slides: Object,
                slide: Object,
                slideId: 0
            };
        },
        created() {
            this.getSlides();
        },
        methods: {
            getSlides: function () {
                this.$http.get("/api/slides").then(function (response) {
                    this.slides = response.body;
                    this.cycleSlide(0);
                });
            },
            cycleSlide: function (slideId) {
                this.slide = this.slides[slideId];
                if (slideId < this.slides.length) {
                    setTimeout(this.cycleSlide, this.slide.duration * 1000, slideId + 1);
                }
                else {
                    this.$events.$emit('cycleCompleted');
                }
            }
        }
    }
</script>

<style scoped>
.slide {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
</style>