<template>
    <div class="row h-100 align-items-center">
        <div class="col-6 offset-3  main motd">
            <p class="quote">“{{ quote.quote }}”</p>
            <p class="from">{{ quote.from }}</p>
        </div>
    </div>
</template>
<script>
    import Quotes from '../Data/quotes.json';

    export default {
        data() {
            return {
                quote: ''
            }
        },
        mounted() {
            this.quote = Quotes[Math.floor(Math.random() * Quotes.length)];
        }
    };

</script>
<style>
    .motd .quote {
        font-size: 5vh;
        font-style: italic;
    }

    .motd .from {
        font-size: 3vh;
        text-align: right;
    }
</style>