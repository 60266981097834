var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "row align-items-center" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "col-6 text-right" }, [
      _vm._v(
        "\n        " +
          _vm._s(_vm._f("moment")(_vm.now, "dddd")) +
          " ⋅ " +
          _vm._s(_vm._f("moment")(_vm.now, "D. MMMM YYYY")) +
          " ⋅ " +
          _vm._s(_vm._f("moment")(_vm.now, "HH:mm")) +
          "\n    "
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-6 text-left" }, [
      _c("span", { staticClass: "fc-icon fc-facelift-cloud " })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }