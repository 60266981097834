var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "row align-items-start" }, [
    _c("div", { staticClass: "col" }, [
      _c(
        "nav",
        { staticClass: "navbar navbar-expand-lg navbar-light bg-faded" },
        [
          _c(
            "router-link",
            { staticClass: "navbar-brand", attrs: { to: "/" } },
            [_vm._v("Signature")]
          ),
          _vm._v(" "),
          _c("ul", { staticClass: "navbar-nav" }, [
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  { staticClass: "nav-link active", attrs: { to: "start" } },
                  [_vm._v("Start")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  { staticClass: "nav-link", attrs: { to: "motd" } },
                  [_vm._v("MOTD")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  { staticClass: "nav-link", attrs: { to: "weather" } },
                  [_vm._v("Weather")]
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }