var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row h-100 align-items-center" }, [
    _c("div", { staticClass: "col-6 offset-3  main motd" }, [
      _c("p", { staticClass: "quote" }, [
        _vm._v("“" + _vm._s(_vm.quote.quote) + "”")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "from" }, [_vm._v(_vm._s(_vm.quote.from))])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }